export default {
	name: 'PurchaseOrderForm',
	created () {
		this.$nextTick(function () {
			this.GetPurchaseOrderForm()
		})
	},
	mounted () {

	},
	data () {
		return {
			PDFsrc: '/Form Pemesanan Waran Terstruktur T5.pdf',
			dataArr: {}
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据
		GetPurchaseOrderForm (ric) {
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetPurchaseOrderForm',
				data: { lang: localStorage.getItem('lang') || 2, id: '' },
				success: (data) => {
					console.log(data)
					this.dataArr = data.text[0]
				},
				error: function (XMLHttpRequest) { }
			})
		}
	}
}
